/* eslint-disable sonarjs/no-duplicate-string */
import React, { ComponentProps, FC, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Stack, Typography } from '@mui/material';
import { Button } from 'common/components';
import { TextField } from 'common/components/ReactHookForm';
import { useTimeout } from 'common/hooks';
import { isFeatureEnabled } from 'common/utils';
import { CommunityAuthenticationMethods } from 'store/apis/community';
import { useCheckIdpMutation } from 'store/apis/user';
import { useKeycloakContext } from 'store/context/KeycloakContext';

import { ProviderButton } from '../ProviderButton';
import { LoginEmbedded } from './LoginEmbedded';
import { Wrapper } from './LoginEmbedded.styled';
import { LoginEmbeddedSkeleton } from './LoginEmbeddedSkeleton';
import { SignupAgreement } from './components/SignupAgreement';
import { DEFAULT_SSO_OPTIONS } from './constants';
import { EmbeddedLoginValues, embeddedLoginSchema } from './embeddedLoginSchema';

type Props = {
  loginLabel: string;
  redirectUrl: string;
  communitySlug?: string;
  communityAuthenticationMethods?: CommunityAuthenticationMethods;
  loadingCommunitySetting?: boolean;
} & ComponentProps<typeof Wrapper>;

export const LoginEmbeddedContainer: FC<Props> = ({
  loginLabel,
  redirectUrl,
  communitySlug,
  communityAuthenticationMethods = DEFAULT_SSO_OPTIONS,
  loadingCommunitySetting: isLoadingCommunitySetting,
  ...props
}) => {
  const { t } = useTranslation('auth');
  const history = useHistory();
  const { login, createLoginUrl, createRegisterUrl } = useKeycloakContext();
  const [checkIdp] = useCheckIdpMutation();
  const [embeddedUrl, setEmbeddedLoginUrl] = useState<string | undefined>(undefined);
  const redirectUri = window.location.origin + redirectUrl;
  const { googleSSOEnabled, microsoftSSOEnabled } = communityAuthenticationMethods;
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [title, setTitle] = useState(loginLabel);

  const methods = useForm<EmbeddedLoginValues>({
    resolver: yupResolver(embeddedLoginSchema),
  });

  const handleLoginWithGoogle = useCallback(() => {
    if (!googleSSOEnabled) return;
    login({ idpHint: 'google', redirectUri });
  }, [login, googleSSOEnabled, redirectUri]);

  const handleLoginWithMicrosoft = useCallback(() => {
    if (!microsoftSSOEnabled) return;
    login({ idpHint: 'microsoft', redirectUri });
  }, [login, microsoftSSOEnabled, redirectUri]);

  const handleAuthenticated = useCallback(() => {
    isFeatureEnabled('UnifiedKcFlow') ? (window.location.href = redirectUrl) : history.replace(redirectUrl);
  }, [history, redirectUrl]);

  const handleSubmit = methods.handleSubmit(async ({ email }) => {
    const result = await checkIdp({ email, communitySlug }).unwrap();
    if (result === 'UNKNOWN') {
      setTitle(t('signUp'));
      setEmbeddedLoginUrl(createRegisterUrl({ loginHint: email, redirectUri }));
    } else if (result === 'NONE') {
      setEmbeddedLoginUrl(createLoginUrl({ loginHint: email, redirectUri }));
    } else {
      login({ idpHint: result, redirectUri });
    }
  });

  /**
   * On the local environment there is an security issue with the iframe messages.
   * As a workaround we will set the iframe as loaded after 5 seconds.
   */
  useTimeout(() => {
    setIsIframeLoaded(true);
  }, 5_000);

  return embeddedUrl ? (
    <Wrapper isLoading={!isIframeLoaded} {...props}>
      <Typography variant="natter-display-sm" fontWeight={600} textAlign="center">
        {title}
      </Typography>

      {!isIframeLoaded && <LoginEmbeddedSkeleton />}
      <LoginEmbedded src={embeddedUrl} onAuthenticated={handleAuthenticated} onLoad={() => setIsIframeLoaded(true)} />
    </Wrapper>
  ) : (
    <FormProvider {...methods}>
      <Wrapper as="form" onSubmit={handleSubmit} {...props}>
        <Typography variant="natter-display-sm" fontWeight={600} textAlign="center">
          {title}
        </Typography>

        {!isLoadingCommunitySetting && (googleSSOEnabled || microsoftSSOEnabled) && (
          <>
            <Stack gap={2}>
              {googleSSOEnabled && <ProviderButton onClick={handleLoginWithGoogle} provider="google" />}
              {microsoftSSOEnabled && <ProviderButton onClick={handleLoginWithMicrosoft} provider="microsoft" />}
            </Stack>

            <Divider flexItem>
              <Typography variant="natter-text-sm" color="text.secondary">
                {t('joinWithEmail')}
              </Typography>
            </Divider>
          </>
        )}

        <TextField name="email" label={t('email')} type="email" fullWidth autoFocus />

        <Stack gap={3}>
          <Button type="submit" isLoading={methods.formState.isSubmitting} size="large" fullWidth>
            {loginLabel}
          </Button>

          <SignupAgreement />
        </Stack>
      </Wrapper>
    </FormProvider>
  );
};
